import * as React from 'react'
import { Switch as SwitchCore, SwitchProps as SwitchCoreProps, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { t } from 'app/frontend/helpers/translations/i18n'
import { FormControlLabelSwitch } from 'app/frontend/components/material/form-control-label'

export const useStyles = makeStyles(theme => ({
  switchBase: {
    color: theme.palette?.grey[50],
    '&$checked': {
      color: theme.palette?.blue[700],
      '& + $track': {
        backgroundColor: theme.palette?.primary.main,
        opacity: 0.5,
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 105, 117, 0.2)',
      },
    },
    '&$disabled': {
      '& + $track': {
        backgroundColor: theme.palette?.grey[400],
        opacity: 0.5,
      },
      '&$checked': {
        opacity: 0.5,
        '& + $track': {
          backgroundColor: '#BFE0E3',
        },
      },
      '&:not($checked) $thumb': {
        backgroundColor: theme.palette?.grey[50],
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(204, 204, 204, 0.2);',
    },
  },
  thumb: {},
  track: {},
  checked: {},
  disabled: {},
  rippleColor: {
    '& .MuiTouchRipple-ripple': {
      opacity: '0.8 !important',
      color: theme.palette?.primary.main,
    },
  },
  rippleColorUnselected: {
    '& .MuiTouchRipple-ripple': {
      opacity: '0.8 !important',
      color: theme.palette?.grey[400],
    },
  },
}))

export interface Props extends SwitchCoreProps {
  id: string
  labelOn?: string
  labelOff?: string
  ariaDescribedby?: string
}

export const Switch: React.FunctionComponent<Props> = ({
  id,
  labelOn,
  labelOff,
  ...otherProps
}) => {
  const styles = useStyles()

  return (
    <Box flexDirection="row">
      <FormControlLabelSwitch
        control={
          <SwitchCore
            id={id}
            {...otherProps}
            classes={{
              switchBase: styles.switchBase,
              thumb: styles.thumb,
              track: styles.track,
              checked: styles.checked,
              disabled: styles.disabled,
            }}
            TouchRippleProps={{
              classes: {
                root: otherProps.checked ? styles.rippleColor : styles.rippleColorUnselected,
              },
            }}
          />
        }
        labelPlacement="start"
        label={otherProps.checked ? labelOn || t('on') : labelOff || t('off')}
      />
    </Box>
  )
}
