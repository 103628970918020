import * as React from 'react'
import { connect } from 'react-redux'
import { change, Field, formValueSelector } from 'redux-form'
import { Checkbox } from 'app/frontend/components/material/checkbox'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import * as styles from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/settings-modal.css'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { WrappedFieldPropsStub } from 'app/frontend/compositions/data/redux-form-fields'
import { MAX_NUM_ATTEMPTS } from 'app/frontend/data/quiz-configuration'
import * as Feature from 'app/frontend/helpers/feature'
import { MaxAttemptsDropdown } from './max-attempts-dropdown'
import { ASSESSMENT_SETTINGS_FORM, FormNames } from '../helper'

const t = tns('teach:assessment_builder')

interface OwnProps {
  isReadOnly: boolean
  maxAttemptsUsed: number
}

interface StateProps {
  maxNumAttempts: number
}

interface DispatchProps {
  setMaxAttempts: (maxAttempts: number) => void
}

interface State {
  isMultipleAttemptsEnabled: boolean
}

type Props = OwnProps & StateProps & DispatchProps

export class _MultipleAttemptsSettingInner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const isMultipleAttemptsEnabled = props.maxNumAttempts > 1
    this.state = { isMultipleAttemptsEnabled }
  }

  toggleMultipleAttempts = () => {
    const { setMaxAttempts } = this.props
    // If multiple attempts is being toggled off, set max attempts to 1, otherwise set it to 2.
    // This assumes that toggling multiple attempts is disabled once a student completes
    // one attempt on the assessment
    const maxAttemptsAllowed = this.state.isMultipleAttemptsEnabled ? 1 : 2
    this.setState({ isMultipleAttemptsEnabled: !this.state.isMultipleAttemptsEnabled })
    setMaxAttempts(maxAttemptsAllowed)
  }

  renderDropdown = () => (field: WrappedFieldPropsStub) => {
    const { maxAttemptsUsed, isReadOnly } = this.props
    const lowerBound = Math.max(2, maxAttemptsUsed)
    return (
      <MaxAttemptsDropdown
        disabled={isReadOnly}
        value={field.input.value}
        onChange={field.input.onChange}
        maxNumAttemptsLowerBound={lowerBound}
        maxNumAttemptsUpperBound={MAX_NUM_ATTEMPTS}
        descriptionId="multiple-attempts-warning"
      />
    )
  }

  render() {
    const { isReadOnly, maxAttemptsUsed } = this.props
    const { isMultipleAttemptsEnabled } = this.state
    // If a student has used more than one attempt already then we
    // don't allow multiple attempts to be turned off
    const isToggleDisabled = isReadOnly || (maxAttemptsUsed > 1 && isMultipleAttemptsEnabled)
    return (
      <Box full="page" separator="top" pad="medium" data-test="multiple-attempts">
        <Box
          full="page"
          responsive={false}
          direction="row"
          justify="between"
          alignItems="center"
          data-test="setting-multiple-attempts"
        >
          <Paragraph margin="none" weight="semibold" id="settings-multiple-attempts-header">
            {t('settings_multiple_attempts_header')}
          </Paragraph>
          <Checkbox
            onChange={this.toggleMultipleAttempts}
            className={styles.checkboxPadding}
            disabled={isToggleDisabled}
            checked={isMultipleAttemptsEnabled}
            inputProps={{
              'aria-labelledby': 'settings-multiple-attempts-header',
              'aria-describedby': 'settings-multiple-attempts-description',
            }}
          />
        </Box>
        <Paragraph size="small" margin="small" id="settings-multiple-attempts-description">
          {t('settings_multiple_attempts_description')}
        </Paragraph>
        {isMultipleAttemptsEnabled && (
          <Box>
            <Box className={styles.dropdownContainerSmall}>
              <Field name={FormNames.MAX_NUM_ATTEMPTS} component={this.renderDropdown()} />
            </Box>
            {Feature.isEnabled('alertOnMultipleAttemptsVersionFlag') && (
              <Paragraph
                id="multiple-attempts-warning"
                margin="small"
                size="small"
                data-test="multiple-attempts-warning"
                className={styles.warning}
              >
                {t('settings_multiple_attempts_warning')}
              </Paragraph>
            )}
          </Box>
        )}
      </Box>
    )
  }
}

const mapStateToProps = (state: any): StateProps => {
  const selector = formValueSelector(ASSESSMENT_SETTINGS_FORM)
  return {
    maxNumAttempts: selector(state, FormNames.MAX_NUM_ATTEMPTS),
  }
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    setMaxAttempts: (maxAttempts: number) => {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.MAX_NUM_ATTEMPTS, maxAttempts))
    },
  }
}

export const MultipleAttemptsSettingInner = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(_MultipleAttemptsSettingInner)
