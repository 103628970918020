import * as React from 'react'
import * as styles from './spinner-atom.css'
import { Box, BoxProps } from 'app/frontend/components/material/box'
import * as LoadingAtom from 'app/public/img/loading-atom.gson'
import Lottie from 'app/frontend/components/material/lottie'

export const AtomSpinnerLottie: React.FunctionComponent<BoxProps> = props => (
  <Box alignItems={'center'} {...props}>
    <Lottie
      path={LoadingAtom}
      nodeProps={{
        className: styles.spinner,
        'data-test': 'spinner-atom',
        role: 'img',
        'aria-label': '',
      }}
    />
  </Box>
)

AtomSpinnerLottie.displayName = 'AtomSpinnerLottie'

export default AtomSpinnerLottie
