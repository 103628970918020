import * as React from 'react'
import { useDispatch } from 'react-redux'
import * as classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { Icon } from 'app/frontend/components/material/icon'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { PlaceholderText } from 'app/frontend/components/material/loading'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import * as styles from './copy-text-input.css'

const t = tns('comps:copy_text_input')

export interface Props {
  label?: string
  value: string
  loading?: boolean
  lightBackground?: boolean
  copyLabel?: string
  inputId?: string
  descriptionId?: string
  dataBi: string
  hideIcon?: boolean
  onClickCopy?: () => void
}

export const CopyTextInput: React.FunctionComponent<Props> = ({
  copyLabel,
  loading,
  lightBackground,
  inputId,
  descriptionId,
  dataBi,
  value,
  onClickCopy,
  hideIcon,
  label,
}) => {
  const dispatch = useDispatch()
  const [copied, setCopied] = React.useState<boolean>(false)

  const onCopy = (_text: string, result: boolean) => {
    if (onClickCopy) {
      onClickCopy()
    }
    if (result) {
      setCopied(true)
    } else {
      dispatch(showSnackbar({ message: t('copy_error') }))
    }
  }

  return (
    <>
      {label && (
        <Paragraph size="small" margin="none">
          <label htmlFor={inputId}>{label}</label>
        </Paragraph>
      )}
      <Box
        pad={{ left: 'medium', right: 'small', top: 'small', bottom: 'small' }}
        background={lightBackground ? 'light' : 'grey-50'}
        direction="row"
        flex="grow"
        justify="between"
        alignItems="center"
        responsive={false}
        margin={{ top: 'small', bottom: 'medium' }}
        separator={lightBackground ? 'all' : 'none'}
        data-bi={dataBi}
        className={styles.copyTextInput}
      >
        {loading ? (
          <PlaceholderText height="1.5rem" width="calc(100% - 0.5rem)" />
        ) : (
          <>
            <Box alignItems="center" direction="row" responsive={false} flex="grow">
              {hideIcon ? null : <Icon name="icon-link" size="large" />}
              <input
                id={inputId}
                aria-describedby={descriptionId}
                type="text"
                value={value}
                readOnly
                className={classNames(styles.input, { [styles.noMargin]: hideIcon })}
              />
            </Box>
            <CopyToClipboard text={value} onCopy={onCopy}>
              <ButtonMaterial
                theme="bordered"
                icon={<Icon name="icon-copy" size="medium" />}
                label={copied ? t('copied') : copyLabel ? copyLabel : t('copy')}
                className={styles.copyLink}
                data-bi={`${dataBi}-button`}
              />
            </CopyToClipboard>
          </>
        )}
      </Box>
    </>
  )
}
