import * as React from 'react'
import * as _ from 'lodash'
import { MenuItem, Select, InputLabel, SelectChangeEvent, Box } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('teach:assessment_builder')

export interface Props {
  value: number
  maxNumAttemptsLowerBound: number
  maxNumAttemptsUpperBound: number
  disabled: boolean
  onChange: (newMaxNumAttempts: number) => void
  descriptionId?: string
}

export class MaxAttemptsDropdown extends React.Component<Props, {}> {
  private generateMaxNumAttemptsChoices = (
    maxNumAttemptsLowerBound: number,
    maxNumAttemptsUpperBound: number
  ) => {
    return _.range(maxNumAttemptsLowerBound, maxNumAttemptsUpperBound + 1).map(num => ({
      value: num.toString(),
      label: num.toString(),
    }))
  }

  private onChange = (event: SelectChangeEvent): void => {
    const newMaxNumAttempts = parseInt(event.target.value, 10)
    this.props.onChange(newMaxNumAttempts)
  }

  render() {
    const {
      value,
      maxNumAttemptsLowerBound,
      maxNumAttemptsUpperBound,
      disabled,
      descriptionId,
    } = this.props
    return (
      <Box>
        <InputLabel id="max-attempts-label" sx={{ fontSize: '0.75rem' }}>
          {t('settings_multiple_attempts_count')}
        </InputLabel>
        <Select
          data-bi="max-attempt-dropdown"
          id="max-attempts-selected"
          MenuProps={{ id: 'max-attempt-dropdown-menu' }}
          disabled={disabled}
          value={value.toString()}
          onChange={this.onChange}
          variant="standard"
          sx={{ flex: 1, maxHeight: '2.5rem' }}
          label={t('settings_multiple_attempts_count')}
          labelId="max-attempts-label"
          aria-describedby={`${descriptionId}`}
        >
          {this.generateMaxNumAttemptsChoices(
            maxNumAttemptsLowerBound,
            maxNumAttemptsUpperBound
          ).map(({ value: optionValue, label }) => (
            <MenuItem key={optionValue} value={optionValue}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }
}
