import * as React from 'react'
import { InputLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { CopyTextInput } from 'app/frontend/compositions/data/copy-text-input'
import * as LtiConstants from 'app/frontend/data/lti-constants'

const t = tns('teach:assessment_builder')

const useStyles = makeStyles(() => ({
  inputLabel: {
    margin: 0,
    fontWeight: 600,
  },
}))
interface Props {
  courseworkId: string
}

export const LtiLinkSetting: React.FunctionComponent<Props> = ({ courseworkId }) => {
  const classes = useStyles()
  const ltiLaunchUrl = `${window.location.origin}${LtiConstants.COURSEWORK_LAUNCH}${courseworkId}`
  const inputId = 'copy-link-for-lti'
  const descriptionId = 'ltiLinkDescription'
  return (
    <Box full="page" pad="medium" data-test="lti-link">
      <InputLabel htmlFor={inputId} className={classes.inputLabel}>
        {t('settings_lti_link_header')}
      </InputLabel>
      <Paragraph id={descriptionId} size="small" margin="small">
        {t('settings_lti_link_description')}
      </Paragraph>
      <Box full="page" direction="row">
        <CopyTextInput
          hideIcon
          value={ltiLaunchUrl}
          copyLabel={t('settings_copy_link')}
          dataBi="copy-lti-link"
          inputId={inputId}
          descriptionId={descriptionId}
        />
      </Box>
    </Box>
  )
}

LtiLinkSetting.displayName = 'LtiLinkSetting'
