import * as React from 'react'
import { InputLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { Box } from 'app/frontend/components/material/box'
import { CopyTextInput } from 'app/frontend/compositions/data/copy-text-input'
import { PlaceholderText } from 'app/frontend/components/material/loading'
import { GetDuplicateAssessmentToken } from 'app/frontend/pages/material/teach/compositions/connected/get-duplicate-assessment-token'
import {
  DUPLICATED_ASSESSMENT_TOKEN_COURSE,
  DUPLICATED_ASSESSMENT_TOKEN_SECTION,
} from 'app/frontend/data/mixpanel-events'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'

const t = tns('teach:assessment_builder')

const useStyles = makeStyles(() => ({
  inputLabel: {
    margin: 0,
    fontWeight: 600,
  },
}))

interface Props {
  assessmentId: string
  courseId: string
  sectionId: string | null
}

export const CopyAssessmentToken: React.FunctionComponent<Props> = ({
  assessmentId,
  courseId,
  sectionId,
}) => {
  const classes = useStyles()
  const inputId = 'copy-link-assessment-token'
  const descriptionId = 'assessmentTokenDescription'

  return (
    <Box full="page" pad="medium" data-test="copy-assessment-token">
      <InputLabel htmlFor={inputId} className={classes.inputLabel}>
        {t('settings_copy_assessment_token_header')}
      </InputLabel>
      <Paragraph id={descriptionId} size="small" margin="small">
        {t('settings_copy_assessment_token_description')}
      </Paragraph>
      <GetDuplicateAssessmentToken assignmentId={assessmentId}>
        {({ duplicateToken, loading, error }) => {
          if (loading || error || !duplicateToken) {
            return <PlaceholderText width="40rem" />
          }
          return (
            <Box full="page" direction="row">
              <CopyTextInput
                hideIcon
                value={duplicateToken}
                copyLabel={t('settings_copy_assessment_token_button_label')}
                inputId={inputId}
                descriptionId={descriptionId}
                dataBi="copy-assessment-token"
                onClickCopy={() => {
                  if (sectionId) {
                    sendEventTeachAssessment(DUPLICATED_ASSESSMENT_TOKEN_SECTION, assessmentId)
                  } else if (courseId) {
                    sendEventTeachAssessment(DUPLICATED_ASSESSMENT_TOKEN_COURSE, assessmentId)
                  }
                }}
              />
            </Box>
          )
        }}
      </GetDuplicateAssessmentToken>
    </Box>
  )
}

CopyAssessmentToken.displayName = 'CopyAssessmentToken'
