import * as React from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { InputLabel } from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { CopyTextInput } from 'app/frontend/compositions/data/copy-text-input'
import { FieldCheckboxLabel } from 'app/frontend/compositions/data/redux-form-fields'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import { LtiLaunchType } from 'app/frontend/data/lti-constants'
import { FormNames, ASSESSMENT_SETTINGS_FORM } from './helper'
import * as styles from '../settings-modal.css'

const t = tns('teach:assessment_builder')

type OwnProps = {
  course: GQL.GetCourse.Course
  section: GQL.GetSection.Section
  isReadOnly: boolean
}

type StateProps = {
  reviewCenterEnabled: boolean
}

type Props = OwnProps & StateProps

export class _ReviewCenterSetting extends React.Component<Props, {}> {
  getReviewCenterLTILaunchUrl = () => {
    const { course, section } = this.props
    const location = window.location.origin

    if (!section) {
      return `${location}/lti/launch?${LtiLaunchType.COURSE_REVIEW_CENTER_LAUNCH_PARAM}=${course.id}`
    }

    return `${location}/lti/launch?${LtiLaunchType.SECTION_REVIEW_CENTER_LAUNCH_PARAM}=${section.id}`
  }

  render() {
    const { reviewCenterEnabled, course, isReadOnly } = this.props
    const inputId = 'copy-link-for-review-center'

    const shouldShowLtiLink = reviewCenterEnabled && course.lmsType !== 'NONE'
    return (
      <Box full="page" pad="medium" separator="top" data-test="review-center-settings">
        <Box full="page" responsive={false} direction="row" justify="between" alignItems="center">
          <FieldCheckboxLabel
            className={styles.fieldCheckbox}
            name={FormNames.REVIEW_CENTER_ENABLED}
            disabled={isReadOnly}
            label={
              <Paragraph margin="none" weight="semibold">
                {t('settings_review_center_header')}
              </Paragraph>
            }
            labelPlacement={'start'}
            aria-describedby="settings-review-center-description"
          />
        </Box>
        <Paragraph size="small" margin="small" id="settings-review-center-description">
          {t('settings_review_center_description')}
        </Paragraph>

        {shouldShowLtiLink && (
          <>
            <InputLabel htmlFor={inputId} className={styles.inputLabel}>
              {t('settings_review_center_lti_link')}
            </InputLabel>
            <Box full="page" direction="row">
              <CopyTextInput
                hideIcon
                value={this.getReviewCenterLTILaunchUrl()}
                copyLabel={t('settings_copy_link')}
                dataBi="copy-review-center-link"
                inputId={inputId}
              />
            </Box>
          </>
        )}
      </Box>
    )
  }
}

function mapStateToProps(state: any): StateProps {
  const selector = formValueSelector(ASSESSMENT_SETTINGS_FORM)

  return {
    reviewCenterEnabled: selector(state, 'reviewCenterEnabled'),
  }
}

export const ReviewCenterSetting = connect<StateProps, {}, OwnProps>(
  mapStateToProps,
  null
)(_ReviewCenterSetting)
