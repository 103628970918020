import * as React from 'react'
import { useState } from 'react'
import { isNull, omit } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { change, getFormValues, stopSubmit, submit } from 'redux-form'
import * as moment from 'moment'
import { useMutation } from '@apollo/client'
import { FooterSpacer, ModalBody } from 'app/frontend/components/material/modal/modal-body'
import { getModalOptions } from 'app/frontend/components/material/modal/modal-reducer'
import { createModal } from 'app/frontend/components/material/modal'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { showSnackbar } from 'app/frontend/components/material/snackbar/snackbar-actions'
import { Paragraph } from 'app/frontend/components/material/paragraph'
import ModalFooter from 'app/frontend/components/material/modal/modal-footer'
import { NoticeBox } from 'app/frontend/components/material/notice-box/notice-box'
import { TeachControllerState } from 'app/frontend/pages/teach/teach-controller-reducer'
import * as UPDATE_ASSESSMENT from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/update-assessment.gql'
import * as UPDATE_ASSESSMENT_OVERRIDE from 'app/frontend/pages/material/teach/update-assignment-override.gql'
import { ButtonMaterial } from 'app/frontend/components/material/button/button'
import { Box } from 'app/frontend/components/material/box'
import { useForm } from 'app/frontend/compositions/connected/redux-form'
import * as Feature from 'app/frontend/helpers/feature'
import * as styles from './settings-modal.css'
import { SettingsModalForm } from './settings-modal-form'
import {
  ASSESSMENT_SETTINGS_FORM,
  convertDurationToMoment,
  convertMomentToDuration,
  FormNames,
  FormValues,
  PasswordType,
} from './form-fields/helper'
import { Heading } from 'app/frontend/components/material/heading'

const t = tns('teach:assessment_builder')

export const ASSESSMENT_SETTINGS_MODAL = 'ASSESSMENT_SETTINGS_MODAL'
export const SUPPORT_LINK =
  'https://support.knewton.com/s/article/Creating-an-Assessment-Quiz-Test-in-Knewton-Alta'

type StateProps = {
  pristine: boolean
  valid: boolean
  submitFailed: boolean
  assessment: GQL.GetAssignment.Assignment
  course: GQL.GetCourse.Course
  section: GQL.GetSection.Section
  isCourseAssignmentOnSection: boolean
  hasStudentStartedAssignment: boolean
  hasEditPermission: boolean
  assignmentOverrides: GQL.GetAssignmentOverride.AssignmentOverride
  formValues: Partial<FormValues>
  viewResultsSettings: GQL.ViewResultsSettings
}

interface Props {
  hideModal: () => void
}

export const SettingsModalInner: React.FunctionComponent<Props> = ({ hideModal }) => {
  const upgradeAssessmentSettingFlag = Feature.isEnabled('upgradeAssessmentSettingFlag')
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()

  const stateProps: StateProps = useSelector<TeachControllerState, StateProps>(
    (state: TeachControllerState) => {
      const {
        assessment = null,
        course = null,
        section = null,
        isCourseAssignmentOnSection = false,
        hasStudentStartedAssignment = false,
        hasEditPermission = false,
        assignmentOverrides = null,
        viewResultsGrade = null,
        viewResultsAnswer = null,
        viewResultsExplanation = null,
        viewResultsComment = null,
      } = getModalOptions(state, ASSESSMENT_SETTINGS_MODAL) || {}
      const { isPristine, hasSubmitFailed, isValid } = useForm(ASSESSMENT_SETTINGS_FORM)
      const formValues = getFormValues(ASSESSMENT_SETTINGS_FORM)(state) as FormValues
      const viewResultsSettings = {
        gradeSetting: viewResultsGrade,
        resultsSetting: viewResultsAnswer,
        answersSetting: viewResultsExplanation,
        commentSetting: viewResultsComment,
      }

      return {
        pristine: isPristine,
        valid: isValid,
        submitFailed: hasSubmitFailed,
        assessment,
        course,
        section,
        isCourseAssignmentOnSection,
        hasStudentStartedAssignment,
        hasEditPermission,
        assignmentOverrides,
        formValues,
        viewResultsSettings,
      }
    },
    shallowEqual
  )

  const [updateAssessmentOverrideSettings] = useMutation<
    GQL.UpdateAssignmentOverride.Mutation,
    GQL.UpdateAssignmentOverride.Variables
  >(UPDATE_ASSESSMENT_OVERRIDE)

  const [updateAssessmentSettings] = useMutation<
    GQL.UpdateAssessment.Mutation,
    GQL.UpdateAssessment.Variables
  >(UPDATE_ASSESSMENT)

  /**
   * Handle submit functionality
   * @param formValues
   */
  const handleSubmit = async (formValues: Partial<FormValues>) => {
    let request
    if (stateProps.isCourseAssignmentOnSection) {
      let startDate = null,
        endDate = null
      // Instructor should be able to change/override dates to a different value even without
      // isSectionOverridesEnable FF enabled. But once the FF is enabled instructor should be able
      // to save the same dates value as an override.
      if (
        stateProps.assessment.startsAt !== formValues.startsAt?.valueOf() ||
        stateProps.assessment.endsAt !== formValues.endsAt?.valueOf() ||
        formValues.startsOrEndsAtOverridden
      ) {
        startDate = formValues.startsAt
        endDate = formValues.endsAt
      }
      request = {
        pathId: stateProps.assessment.id,
        sectionId: stateProps.section.id,
        startsAt: startDate?.valueOf() || null,
        endsAt: endDate?.valueOf() || null,
        password: formValues.passwordOverridden ? formValues.password : null,
        overrideCoursePassword: formValues.passwordOverridden,
        useLdb: formValues.ldbOverridden ? formValues.useLdb : null,
      }
      setIsSaving(true)

      try {
        await updateAssessmentOverrideSettings({
          variables: { request },
        })
        onMutationComplete()
      } catch (error) {
        onMutationError()
      }
    } else {
      const maxDuration = convertMomentToDuration(formValues.maxDuration)

      const viewResultSettingsInput = {
        gradeSetting: formValues.viewResultsGrade,
        answersSetting: formValues.viewResultsExplanation,
        commentSetting: formValues.viewResultsComment,
        resultsSetting: formValues.viewResultsAnswer,
      }
      request = {
        id: stateProps.assessment.id,
        ...omit(
          formValues,
          FormNames.PASSWORD_TYPE,
          FormNames.COURSE_PASSWORD,
          FormNames.PASSWORD_ENABLED,
          FormNames.STARTS_OR_ENDS_AT_OVERRIDDEN,
          FormNames.PASSWORD_OVERRIDDEN,
          FormNames.LDB_OVERRIDDEN,
          FormNames.STARTS_AT_TIME,
          FormNames.ENDS_AT_TIME,
          FormNames.VIEW_RESULTS_ANSWER_SETTING,
          FormNames.VIEW_RESULTS_COMMENT,
          FormNames.VIEW_RESULTS_GRADE_SETTING,
          FormNames.VIEW_RESULTS_EXPLANATION_SETTING
        ),
        startsAt: formValues.startsAt?.valueOf(),
        endsAt: formValues.endsAt?.valueOf(),
        maxDuration,
        ...(upgradeAssessmentSettingFlag && { viewResultSettingsInput }),
      }
      setIsSaving(true)
      try {
        await updateAssessmentSettings({
          variables: { request },
        })
        onMutationComplete()
      } catch (error) {
        onMutationError()
      }
    }
  }

  /**
   * Function to execute after mutation completed
   */
  const onMutationComplete = () => {
    setIsSaving(false)
    hideModal()
    dispatch(
      showSnackbar({ message: t('update_success'), iconName: 'icon-progress-circle-complete' })
    )
  }

  /**
   * Function to execute on mutation error
   */
  const onMutationError = () => {
    setIsSaving(false)
    dispatch(showSnackbar({ message: t('update_error') }))
  }

  if (!stateProps.assessment) {
    return null
  }

  const { name, label, startsAt, endsAt } = stateProps.assessment
  const {
    reviewCenterEnabled,
    shuffleQuestions,
    viewResultsConfig,
    maxNumAttempts,
    printable,
    printableVariantsCount,
    password,
    useLdb,
    isPasswordProtected,
    viewResultsSettings: viewResultsSettingsConfig,
  } = stateProps.assessment.quizConfiguration

  const {
    overrideCoursePassword: isCoursePasswordOverridden,
    password: overriddenPassword,
    startsAt: overriddenStartsAt,
    endsAt: overriddenEndsAt,
    useLdb: overriddenUseLdb,
  } = stateProps?.assignmentOverrides || {}

  const isAnyFieldOverridden =
    stateProps?.formValues?.passwordOverridden ||
    stateProps?.formValues?.startsOrEndsAtOverridden ||
    stateProps?.formValues?.ldbOverridden
  // TODO - remove this conditional check, when section override follow-up flag is removed
  const assessmentPassword = isCoursePasswordOverridden ? overriddenPassword : password
  const assessmentStartAt = overriddenStartsAt ?? startsAt
  const assessmentEndsAt = overriddenEndsAt ?? endsAt
  const assessmentUseLdb = overriddenUseLdb ?? useLdb
  const passwordType =
    isCoursePasswordOverridden || !isPasswordProtected ? PasswordType.SECTION : PasswordType.COURSE

  const onResetToAllField = () => {
    onFieldReset(new Set([FormNames.STARTS_AT, FormNames.PASSWORD, FormNames.USE_LDB]))
  }

  const onFieldReset = (resetFieldNames: Set<FormNames>) => {
    if (resetFieldNames.has(FormNames.STARTS_AT)) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.STARTS_OR_ENDS_AT_OVERRIDDEN, false))
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.STARTS_AT, moment(startsAt)))
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.ENDS_AT, moment(endsAt)))
    }
    if (resetFieldNames.has(FormNames.PASSWORD)) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_OVERRIDDEN, false))
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD, password))
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.PASSWORD_ENABLED, !!password))
      dispatch(
        change(
          ASSESSMENT_SETTINGS_FORM,
          FormNames.PASSWORD_TYPE,
          isPasswordProtected ? PasswordType.COURSE : PasswordType.SECTION
        )
      )
      // reset the form level submitting and submitFailed status
      dispatch(stopSubmit(ASSESSMENT_SETTINGS_FORM))
    }
    if (resetFieldNames.has(FormNames.USE_LDB)) {
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.LDB_OVERRIDDEN, false))
      dispatch(change(ASSESSMENT_SETTINGS_FORM, FormNames.USE_LDB, useLdb))
    }
  }

  return (
    <>
      <ModalBody>
        <Heading tag="h1" size="h3" id="modalTitle">
          {t('assessment_settings')}
        </Heading>
        {!stateProps.isCourseAssignmentOnSection && !stateProps.hasEditPermission && (
          <NoticeBox iconName="icon-lock">
            <Paragraph className={styles.message} data-test="notice-box-permission">
              {t('settings_section_level_instructor_warning')}
            </Paragraph>
          </NoticeBox>
        )}
        {stateProps.isCourseAssignmentOnSection && (
          <Box
            direction="row"
            margin={{ bottom: 'large' }}
            pad={{ vertical: 'small' }}
            className={styles.supportBanner}
            data-test="section-override-support-link-banner"
          >
            {t('section_override_assessment_setting_support_link_text')}
            <a
              target="_blank"
              href={SUPPORT_LINK}
              className={styles.supportLink}
              data-bi="section-override-support-link"
            >
              {t('section_override_assessment_setting_support_link')}
            </a>
          </Box>
        )}
        <SettingsModalForm
          isCourseAssignmentOnSection={stateProps.isCourseAssignmentOnSection}
          hasStudentStartedAssignment={stateProps.hasStudentStartedAssignment}
          hasEditPermission={stateProps.hasEditPermission}
          assessment={stateProps.assessment}
          course={stateProps.course}
          section={stateProps.section}
          resetValues={{ startsAt, endsAt }}
          initialValues={{
            name,
            label,
            startsAt: moment(assessmentStartAt),
            endsAt: moment(assessmentEndsAt),
            reviewCenterEnabled,
            shuffleQuestions,
            viewResultsConfig,
            maxNumAttempts,
            maxDuration: convertDurationToMoment(
              stateProps.assessment.quizConfiguration.maxDuration
            ),
            printable,
            printableVariantsCount: printableVariantsCount ?? 1,
            passwordEnabled: !!assessmentPassword,
            password: assessmentPassword,
            coursePassword: password,
            passwordType,
            useLdb: assessmentUseLdb,
            startsOrEndsAtOverridden:
              !!stateProps.assignmentOverrides?.startsAt ||
              !!stateProps.assignmentOverrides?.endsAt,
            passwordOverridden: !!stateProps.assignmentOverrides?.overrideCoursePassword,
            ldbOverridden: !isNull(stateProps.assignmentOverrides?.useLdb),
            ...(upgradeAssessmentSettingFlag && {
              viewResultsGrade: viewResultsSettingsConfig?.gradeSetting,
              viewResultsAnswer: viewResultsSettingsConfig?.resultsSetting,
              viewResultsExplanation: viewResultsSettingsConfig?.answersSetting,
              viewResultsComment: viewResultsSettingsConfig?.commentSetting,
            }),
          }}
          onSubmit={handleSubmit}
          onFieldReset={onFieldReset}
        />
        <FooterSpacer />
      </ModalBody>
      <ModalFooter sticky={true}>
        {stateProps.submitFailed && !stateProps.valid ? (
          <Paragraph data-test="settings-change-error" size="small">
            {t('settings_error_message')}
          </Paragraph>
        ) : (
          stateProps.isCourseAssignmentOnSection &&
          isAnyFieldOverridden && (
            <ButtonMaterial
              className={styles.resetLink}
              onClick={onResetToAllField}
              theme="bordered-light"
              label={t('reset_all_to_course_setting')}
              data-bi="reset-all-to-course-settings"
            />
          )
        )}
        <ButtonMaterial
          theme="bordered"
          onClick={hideModal}
          label={t('cancel')}
          data-bi={'settings-modal-footer-cancel-button'}
        />
        <ButtonMaterial
          onClick={() => {
            dispatch(submit(ASSESSMENT_SETTINGS_FORM))
          }}
          type="submit"
          disabled={isSaving || stateProps.pristine || !stateProps.valid}
          label={t('save')}
          data-bi="settings-modal-footer-submit-button"
        />
      </ModalFooter>
    </>
  )
}

export const ModalComponent = createModal()

export const SettingsModal: React.FunctionComponent = () => {
  return (
    <ModalComponent
      name={ASSESSMENT_SETTINGS_MODAL}
      dataBi="assessment-settings-modal"
      fullScreen={true}
      preventClose={true}
    >
      {({ hideModal }) => <SettingsModalInner hideModal={hideModal} />}
    </ModalComponent>
  )
}

SettingsModal.displayName = 'SettingsModal'
